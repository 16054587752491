
export default {
  props: {
    forChoseModal: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      default: ""
    },
    visibleIcon: {
      type: Boolean,
      default: false
    }
  },
  computed:{
		marksCategory(){
			return this.$route.params.category && this.$route.params.category === 'new'?
			'new'
			:'cars'
		},
    displayedTitle() {
      return this.slug === 'mercedes-benz' 
        ? 'Mercedes' 
        : this.item.title || this.item.name;
    },
    yearText() {
      const start = this.item.year_begin || '';
      const end = this.item.year_end ? ` - ${this.item.year_end}` : '';
      return `${start}${end}`;
    }
	}
};
